import axios from "axios";
import {
  IScheduleExchangeBookReq,
  IScheduleExchangeBookRes,
  Uuid,
} from "redmond";

import { config } from "../../config";
import { scheduleExchangeBookFinalizePath } from "../paths";
import { PaymentOpaqueValue } from "@b2bportal/purchase-api/lib/api";

const scheduleExchangeBookFinalize = (
  sessionId: Uuid,
  payments: Array<PaymentOpaqueValue>,
  originalItineraryId: Uuid,
  cartQuoteId: Uuid,
  idempotencyKey: Uuid
): Promise<IScheduleExchangeBookRes> =>
  new Promise(async (resolve, reject) => {
    try {
      const req: IScheduleExchangeBookReq = {
        sessionId,
        payments,
        originalItineraryId,
        cartQuoteId,
        idempotencyKey,
      };
      const res = await axios.post(scheduleExchangeBookFinalizePath, req, {
        baseURL: config.baseURL,
      });

      if (
        res.data.AirExchangeBookScheduleResponse ===
        "AirExchangeBookScheduleSuccess"
      ) {
        resolve(res.data);
      } else {
        reject(res.data.error);
      }
    } catch (e) {
      reject(e);
    }
  });

export default scheduleExchangeBookFinalize;
